import {RemixBrowser, useLocation, useMatches} from '@remix-run/react';
import * as Sentry from '@sentry/remix';
import {StrictMode, startTransition, useEffect} from 'react';
import {hydrateRoot} from 'react-dom/client';

/**
 * By default, Remix will handle hydrating your app on the client for you.
 * You are free to delete this file if you'd like to, but if you ever want it revealed again, you can run `npx remix reveal` ✨
 * For more information, see https://remix.run/file-conventions/entry.client
 */

Sentry.init({
  dsn: 'https://2eda28000e7705cb80c0ef40501c526c@o172672.ingest.us.sentry.io/4506970811400192',
  integrations: [
    Sentry.browserTracingIntegration({
      useEffect,
      useLocation,
      useMatches,
    }),
    Sentry.replayIntegration(),
  ],
  replaysOnErrorSampleRate: 1,
  replaysSessionSampleRate: 0.1,
  tracesSampleRate: 1,
});

startTransition(() => {
  hydrateRoot(
    document,
    <StrictMode>
      <RemixBrowser />
    </StrictMode>,
  );
});
